import React from 'react';
import styled from 'styled-components';
import { changeOpacity, darkGreen, turquoise } from '../styles/colors';
import Button, { ButtonVariants } from './button';
import { maxWidth } from '../styles/text';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const Container = styled.section`
  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 3vh;
  z-index: 5;

  @media (min-width: 400px) {
    left: auto;
    right: 3rem;
  }

  /* hide when too small (landscape / mobile) viewports */
  @media (orientation: landscape) and (max-width: ${maxWidth}px) and (max-height: 400px) {
    display: none;
  }
`;

// TODO: remove `!important`
const StyledButton = styled(Button)`
  margin-bottom: 0 !important;
  box-shadow: 1px 1px 4px 2px ${changeOpacity(darkGreen, 0.5)} !important;
`;

const FloatingButton = ({ content }) => (
  <Container>
    <StyledButton
      title="Maak direct een afspraak"
      url="/contact#form"
      variant={ButtonVariants.light}
      onClick={() => {
        trackCustomEvent({
          // string - required - The object that was interacted with (e.g.video)
          category: 'cta',
          // string - required - Type of interaction (e.g. 'play')
          action: 'Click',
          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
          label: 'Floating button clicked',
          // number - optional - Numeric value associated with the event. (e.g. A product ID)
          // value: 43
        });
      }}
    />
  </Container>
);

FloatingButton.propTypes = {};
FloatingButton.defaultProps = {};

export default FloatingButton;
