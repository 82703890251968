export const parseTextOnlyContent = (node) => {
  // "{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Akkoord","marks":[],"data":{}}]}]}"

  const content = JSON.parse(node.raw);
  if (
    content.nodeType === 'document' &&
    content.content.length > 0 &&
    content.content[0].nodeType === 'paragraph' &&
    content.content[0].content.length === 1 &&
    content.content[0].content[0].nodeType === 'text' &&
    content.content[0].content[0].marks.length === 0
  ) {
    // return a single string
    return content.content[0].content[0].value;
  } else {
    return content;
  }
};
