import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { black, white } from '../styles/colors';
import { flipRowToColumn, maxWidth, menuFlipBorder } from '../styles/text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Button, { ButtonVariants } from './button';
import { CookieNames, isSSR, setCookie } from './helpers/cookie';
import InlineLink from './inlineLink';
import TextContainer from './textContainer';
import { parseTextOnlyContent } from './helpers/parseTextOnlyContent';

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <>
        &nbsp;
        <InlineLink data={node.data} content={node.content} />
      </>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
  },
};

const Cookiewall = ({ hash, acceptTitle, rejectTitle, content, onChange }) => {
  const [show, setShow] = useState(true);

  const handleAccept = useCallback(() => {
    setShow(false);

    if (!isSSR) {
      setCookie({ key: CookieNames.default, value: hash });
      setCookie({ key: CookieNames.allowed, value: 'true' });
    }

    onChange(true);
  }, [hash, onChange]);

  const handleDecline = useCallback(() => {
    setShow(false);

    if (!isSSR) {
      setCookie({ key: CookieNames.default, value: hash });
      setCookie({ key: CookieNames.allowed, value: 'false' });
    }

    onChange(false);
  }, [hash, onChange]);

  return (
    <Container show={show}>
      <TextWrapper>
        <Text>{renderRichText(content, options)}</Text>
        <Buttons>
          <Button
            as="button"
            type="button"
            title={parseTextOnlyContent(rejectTitle)}
            variant={ButtonVariants.secondary}
            onClick={handleDecline}
          />
          <Button
            as="button"
            type="button"
            title={parseTextOnlyContent(acceptTitle)}
            variant={ButtonVariants.primary}
            onClick={handleAccept}
          />
        </Buttons>
      </TextWrapper>
    </Container>
  );
};

export default Cookiewall;

const Container = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${black};
  color: ${white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  margin: 0;
`;

const Text = styled.div`
  display: flex;
  flex-wrap: wrap;
  break-word: break-word;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  padding: 0 1rem;

  @media (min-width: ${menuFlipBorder}px) {
    padding: 0;
    width: 50%;
    justify-content: flex-start;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: 400px) {
    flex-direction: row;
  }

  @media (min-width: ${menuFlipBorder}px) {
    width: 50%;
    justify-content: flex-end;
  }

  & > button {
    margin: 1rem;
  }
`;

const TextWrapper = styled(TextContainer)`
  display: flex;
  align-items: center;
  padding: 1rem 4vw;
  flex-direction: column;
  margin: 0;
  width: 100%;

  @media (min-width: ${flipRowToColumn}px) {
    padding: 1rem 6vw;
    max-width: ${maxWidth}px;
  }

  @media (min-width: ${menuFlipBorder}px) {
    flex-direction: row;
    padding: 1rem 0;
  }
`;
