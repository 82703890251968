export const isSSR = typeof window === 'undefined';

const getCookies = () =>
  document.cookie
    .split('; ')
    .map((cookie) => {
      const c = cookie.split('=');
      return { [c[0]]: c[1] };
    })
    .reduce((previous, current) => {
      return { ...previous, ...current };
    }) || '';

export const findCookie = (hash) => {
  if (!isSSR) {
    const cookies = getCookies();
    let hashed = false;

    // check if cookie is set
    if (cookies && cookies.knc == hash) {
      hashed = true;

      if (cookies.kncal === undefined) {
        // missing allow cookie, so show popup while not sure to load 3rd party plugins
        return { hash: false, allowed: false };
      }

      // check if any type of pixels are allowed
      return { hash: hashed, allowed: cookies.kncal === 'true' };
    }
  }

  return { hash: false, allowed: false };
};

export const setCookie = ({ value, key }) => {
  document.cookie = `${key}=${value}; max-age=31536000; path=/`;
};

export const CookieNames = {
  default: 'knc',
  allowed: 'kncal',
};
