import React, { useCallback, useState } from 'react';
import { graphql, Script } from 'gatsby';
import Layout from '../components/layout';
import { Meta } from '../components/seo';
import Paragraph from '../components/paragraph';
import PropTypes from 'prop-types';
import Quote from '../components/quote';
import Contact from '../components/contact';
import Divider from '../components/divider';
import Timeline from '../components/timeline';
import FullWidthImage from '../components/fullWidthImage';
import ContactForm from '../components/contactForm';
import FloatingButton from '../components/floatingButton';
import BuyButton from '../components/buyButton';
import { isProductionEnvironment } from '../components/helpers/environment';
import CallToActionTitle from '../components/callToActionTitle';
import { findCookie, isSSR, setCookie } from '../components/helpers/cookie';
import Cookiewall from '../components/cookiewall';
import SSRProvider from '../components/contexts/ssr';

function parseSlugIds(slugIds) {
  // TODO: useMemo?
  return slugIds.allContentfulPage.edges.reduce((a, b) => {
    const reference = a.node !== undefined ? { [a.node.id]: a.node.slug } : a;
    reference[b.node.id] = b.node.slug;
    return reference;
  });
}

function createContent(item, slugIds, formApiURL) {
  switch (item.type.toLowerCase()) {
    case 'paragraph':
      return (
        <Paragraph
          key={`p${item.id.substring(0, 8)}`}
          content={item}
          slugIds={slugIds}
        />
      );
    case 'quote':
      return (
        <Quote
          key={`q${item.id.substring(0, 8)}`}
          title={item.quoteTitle}
          person={item.quotePerson}
          type={item.quoteType}
          hideQuoteCharacters={item.hideQuoteCharacters}
          message={item.quote}
        />
      );
    case 'calltoactiontitle':
      return (
        <CallToActionTitle
          key={`ca${item.id.substring(0, 8)}`}
          title={item.title}
          type={item.titleType}
          textSize={item.textSize}
          textAlignment={item.textAlignment}
          button={item.button}
        />
      );
    case 'contact':
      return (
        <Contact
          key={`c${item.id.substring(0, 8)}`}
          message={item.message}
          icon={item.icon}
          backgroundImage={item.backgroundImage}
          tagline={item.tagline}
        />
      );
    case 'contactform':
      return (
        <ContactForm
          key={`cf${item.id.substring(0, 8)}`}
          url={formApiURL}
          type={item.contactType}
          metadata={item.metadata}
          introduction={item.introduction}
          backgroundImage={item.backgroundImage}
          formSendText={item.formSendText}
          formFields={item.formFields}
          formMessage={item.formMessage}
          message={item.message}
          footNotes={item.footNotes}
        />
      );
    case 'divider':
      return (
        <Divider key={`d${item.id.substring(0, 8)}`} type={item.dividerType} />
      );
    case 'timeline':
      return (
        <Timeline
          key={`ti${item.id.substring(0, 8)}`}
          title={item.title}
          description={item.description}
          entries={item.entries}
        />
      );
    case 'fullwidthimage':
      return (
        <FullWidthImage
          key={`fwi${item.id.substring(0, 8)}`}
          image={item.image}
        />
      );
    case 'buybutton':
      return (
        item.active && (
          <BuyButton
            key={`cf${item.id.substring(0, 8)}`}
            text={item.text}
            code={isProductionEnvironment ? item.code : item.codeTest}
          />
        )
      );
      break;
    default:
      return null;
  }
}

// TODO: import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; see https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer => skip to import { renderRichText } from 'gatsby-source-contentful/rich-text'; ?
const Page = ({ data, ...props }) => {
  const cookie = findCookie(props.pageContext.cookieHash);
  const [cookieAccepted, setCookieAccepted] = useState(
    (cookie.hash === true && cookie.allowed) || false
  );

  const handleCookieChange = useCallback((accepted) => {
    setCookieAccepted(accepted);
  }, []);

  return (
    <Layout
      showAnkeiler={props.pageContext.showAnkeiler}
      hasHeader={props.pageContext.headerReference}
    >
      <SSRProvider>
        {props.pageContext.content.map((item) =>
          createContent(
            item,
            parseSlugIds(data),
            data.site.siteMetadata.formApiURL
          )
        )}
        {props.pageContext.floatingButton && <FloatingButton />}
        {!isSSR && cookie.hash === false && (
          <Cookiewall
            hash={props.pageContext.cookieHash}
            acceptTitle={props.pageContext.texts['cookie-button-accept']}
            rejectTitle={props.pageContext.texts['cookie-button-reject']}
            content={props.pageContext.texts['cookie-line']}
            onChange={handleCookieChange}
          />
        )}
        {!isSSR && cookieAccepted && (
          <>
            <Script id="facebook-pixel" strategy="post-hydrate">
              {`
!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '442348257896110');fbq('track', 'PageView');
                    `}
            </Script>
            <noscript>
              <img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?id=442348257896110&ev=PageView&noscript=1"
                alt="facebook pixel"
              />
            </noscript>
          </>
        )}
      </SSRProvider>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object,
};
Page.defaultProps = {
  pageContext: {},
};

export default Page;

export const Head = (props) => <Meta {...props} />;

export const query = graphql`
  {
    allContentfulParagraph(
      filter: { title: { title: { eq: "Privacy statement" } } }
    ) {
      edges {
        node {
          id
          title {
            title
          }
          content {
            raw
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          __typename
          id
          description
          slug
        }
      }
    }
    site {
      siteMetadata {
        formApiURL
        ...SiteMetaData
      }
    }
  }
`;
