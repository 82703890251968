import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  blueColor,
  greyColor,
  lightGreen,
  lightOrangeColor,
  orangeBlueStopColor,
  darkGreen,
  white,
} from '../styles/colors';
import { maxWidth, paragraphSpacing } from '../styles/text';
import { Bold, Italic, Underline } from './helpers/styling';
import { FontWeights } from '../styles/fonts';
import {
  darkContainerGradient,
  lightContainerGradient,
} from './images/backgroundContainer';
import BuyButton from './buyButton';
import { isProductionEnvironment } from './helpers/environment';
import Button, { ButtonVariants } from './button';
import replaceTextWithComponents from './helpers/replaceTextWithComponents';

const BaseButton = styled(Button)`
  margin-bottom: 0;
`;
const Title = styled.h2`
  margin: 0 auto;
  margin-bottom: 2rem;
  color: inherit;
  font-weight: ${FontWeights.montSerrat.normal};
  max-width: ${maxWidth}px;
  text-decoration: none;
  font-size: ${(props) => (props.textSize === 'medium' ? '2rem' : '3rem')};
  line-height: ${(props) =>
    props.textSize === 'medium' ? '2.75rem' : '3.75rem'};
`;

const Container = styled.div`
  display: block;
  padding: ${(props) => props.paragraphSpacing} 10vw;
  margin: 0 auto;
  line-height: 2rem;
  position: relative;
  text-align: ${(props) =>
    props.textAlignment === 'left'
      ? 'left'
      : props.textAlignment === 'right'
      ? 'right'
      : 'center'};

  /* TODO: define colors from colors.js */
  ${(props) => props.styleType}
`;

const options = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
};

const CallToActionTitle = ({
  title,
  style,
  type,
  textAlignment,
  textSize,
  button,
}) => {
  let styleType;

  switch (type.toLowerCase()) {
    case 'transparent':
      styleType = `color: ${lightGreen};`;
      break;
    case 'dark':
      styleType = `background-color: ${darkGreen};color: ${white};`;
      break;
    case 'light':
      styleType = `background-color: ${lightGreen};color: ${white};`;
      break;
    case 'gradient':
      styleType = `background-image: linear-gradient(45deg, ${blueColor}, ${orangeBlueStopColor}, ${lightOrangeColor});color: ${greyColor};`;
      break;
    case 'lightgradient':
      styleType = lightContainerGradient;
      break;
    case 'darkgradient':
      styleType = `${darkContainerGradient};color: ${white}`;
      break;
  }

  options.renderText = (text) => replaceTextWithComponents(text, styleType);

  return (
    <Container
      style={style}
      styleType={styleType}
      textAlignment={textAlignment}
      paragraphSpacing={paragraphSpacing}
    >
      {title && (
        <Title textSize={textSize}>{renderRichText(title, options)}</Title>
      )}
      {button && button.code && (
        <BuyButton
          isSection={false}
          text={button.text}
          code={isProductionEnvironment ? button.code : button.codeTest}
        ></BuyButton>
      )}
      {button && button.code === undefined && (
        <BaseButton
          title={button.title}
          url={button.url}
          rel="noopener"
          variant={ButtonVariants.callout}
        />
      )}
    </Container>
  );
};

CallToActionTitle.propTypes = {
  title: PropTypes.object,
  textAlignment: PropTypes.string,
  textSize: PropTypes.string,
  type: PropTypes.string,
  button: PropTypes.object,
  style: PropTypes.object,
};
CallToActionTitle.defaultProps = {
  title: {},
  textAlignment: ``,
  textSize: ``,
  type: ``,
  button: undefined,
  style: {},
};

export default CallToActionTitle;
